// src/VOStream.js
import React, { useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';
import styles from './VOStream.module.css';

const VOStream = () => {
  const [voice, setVoice] = useState('alloy');
  const [isFetching, setIsFetching] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const textareaRef = useRef(null);
  const audioRef = useRef(null);
  const socketRef = useRef(null);
  const mediaSourceRef = useRef(null);
  const sourceBufferRef = useRef(null);
  const playTimeoutRef = useRef(null);
  const audioChunks = useRef([]);

  const fetchAndStreamSpeech = async (text) => {
    setIsFetching(true);
    setAudioBlob(null);
    audioChunks.current = [];

    if (!socketRef.current) {
      socketRef.current = io(process.env.REACT_APP_SERVER_URL);

      socketRef.current.on('audio-chunk', (chunk) => {
        audioChunks.current.push(chunk);
        if (sourceBufferRef.current && !sourceBufferRef.current.updating) {
          sourceBufferRef.current.appendBuffer(chunk);
        }
      });

      socketRef.current.on('end-audio-stream', () => {
        setIsFetching(false);
        if (socketRef.current) {
          socketRef.current.disconnect();
          socketRef.current = null;
        }
        const checkAndEndStream = () => {
          if (!sourceBufferRef.current.updating) {
            mediaSourceRef.current.endOfStream();
          } else {
            sourceBufferRef.current.addEventListener('updateend', () => {
              if (!sourceBufferRef.current.updating) {
                mediaSourceRef.current.endOfStream();
              }
            }, { once: true });
          }
        };
        checkAndEndStream();

        // Convert audio chunks to a blob for download
        const blob = new Blob(audioChunks.current, { type: 'audio/mpeg' });
        setAudioBlob(blob);
      });
    }

    if (sourceBufferRef.current) {
      try {
        sourceBufferRef.current.abort();
        const duration = mediaSourceRef.current.duration;
        if (duration > 0) {
          sourceBufferRef.current.remove(0, duration);
        }
      } catch (error) {
        console.error('Error clearing SourceBuffer:', error);
      }

      mediaSourceRef.current = new MediaSource();
      audioRef.current.src = URL.createObjectURL(mediaSourceRef.current);

      mediaSourceRef.current.addEventListener('sourceopen', () => {
        sourceBufferRef.current = mediaSourceRef.current.addSourceBuffer('audio/mpeg');
        sourceBufferRef.current.addEventListener('updateend', () => {
          if (audioRef.current.paused && !sourceBufferRef.current.updating) {
            if (playTimeoutRef.current) {
              clearTimeout(playTimeoutRef.current);
            }
            playTimeoutRef.current = setTimeout(() => {
              audioRef.current.play();
            }, 1000); // Delay playback by 1 second
          }
        });
      });
    }

    socketRef.current.emit('start-audio-stream', { text, voice });
  };

  const handleCreateClick = () => {
    const text = textareaRef.current.value.trim();
    if (text === '') {
      alert('Please enter some text');
      return;
    }
    fetchAndStreamSpeech(text);
  };

  useEffect(() => {
    if (audioRef.current) {
      mediaSourceRef.current = new MediaSource();
      audioRef.current.src = URL.createObjectURL(mediaSourceRef.current);

      mediaSourceRef.current.addEventListener('sourceopen', () => {
        if (!sourceBufferRef.current) {
          sourceBufferRef.current = mediaSourceRef.current.addSourceBuffer('audio/mpeg');

          sourceBufferRef.current.addEventListener('updateend', () => {
            if (audioRef.current.paused && !sourceBufferRef.current.updating) {
              if (playTimeoutRef.current) {
                clearTimeout(playTimeoutRef.current);
              }
              playTimeoutRef.current = setTimeout(() => {
                audioRef.current.play();
              }, 1000); // Delay playback by 1 second
            }
          });
        }
      });
    }
  }, [audioRef]);

  return (
    <div className={styles.container}>
      <div className={styles.inputArea}>
        <textarea
          ref={textareaRef}
          className={styles.textarea}
          placeholder="Enter your text here"
        ></textarea>
        <select
          className={styles.dropdown}
          value={voice}
          onChange={(e) => setVoice(e.target.value)}
        >
          <option value="alloy">Alloy</option>
          <option value="echo">Echo</option>
          <option value="fable">Fable</option>
          <option value="onyx">Onyx</option>
          <option value="nova">Nova</option>
          <option value="shimmer">Shimmer</option>
        </select>
        <div className={styles.audioBar}>
          <audio ref={audioRef} controls className={styles.audioControl}></audio>
          {audioBlob && (
            <a
              href={URL.createObjectURL(audioBlob)}
              download="output.mp3"
              className={styles.downloadLink}
            >
              Download Cleaned Audio
            </a>
          )}
        </div>
        <button
          className={styles.createButton}
          onClick={handleCreateClick}
          disabled={isFetching}
        >
          {isFetching ? 'Loading...' : 'Create'}
        </button>
      </div>
    </div>
  );
};

export default VOStream;
